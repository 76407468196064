import React from "react";

const BbqMenu = () => {
  return (
    <>
      <div className="menu_item">
        <h4>Pick any 3</h4>
        <ul className="no-bullet">
          <li>Minted infused Lamb Chops (£3 supplement per person)</li>
          <li>Tim’s homemade prime beef burgers with mature cheddar slices</li>
          <li>Thai style steamed seabass</li>
          <li>Prime old English pork sausage or prime pork &amp; apple sausage</li>
          <li>Marinated Cheshire pork steak</li>
          <li>Boneless chicken thighs with sticky BBQ sauce</li>
          <li>Peri Peri chicken breast</li>
          <li>Teriyaki salmon fillet</li>
          <li>Marinated sticky BBQ spare ribs</li>
          <li>Marinated Haloumi burger with guacamole, rocket, mint & tomatoes finished with a pomegranate molasse (v)</li>
          <li>Portobello &amp; peach burger (v)</li>
          <li>Angus sirloin steak carved on the premises and cooked to order (£3.50 supplement per person)</li>
        </ul>
      </div>


      <div className="menu_item">
        <h4>Now choose 3 sides</h4>
        <ul className="no-bullet">
          <li>Garlic & potato salad Waxy potatoes, Dijon mustard, lemon juice finished with chopped chives</li>
          <li>Tanzanian vegetable rice salad</li>
          <li>Lemon & pomegranate couscous - topped with fresh mint & coriander</li>
          <li>Garden pea, cucumber & mint salad</li>
          <li>Cannellini bean, feta & mint salad</li>
          <li>Classic English garden salad</li>
          <li>Classic Caesar salad with homemade dressing</li>
          <li>Tim’s home-made sweet coleslaw</li>
          <li>Summer Macaroni salad - with crispy pancetta, & petit peas in a creamy lemon-thyme dressing</li>
        </ul>
        <br/>
        <p>Includes fresh rolls, condiments, good quality disposable plates and cutlery All our dressings and sauce are made in house from scratch; nothing is bought in.</p>


        <div className="pricing">
          <span>£21.95 per person - minimum 45 people</span>
        </div>

      </div>


    </>
  );
};

export default BbqMenu;