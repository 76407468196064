import React from "react";

const DesertsMenu = () => {
  return (
    <>
      <div className="menu_item">
        <h4>Desserts</h4>

        <ul className="no-bullet">
          <li>Victoria Sponge - Vanilla sponge, strawberry jam, vanilla buttercream or fresh cream</li>
          <li>Lemon - Lemon sponge, lemon buttercream, lemon icing</li>
          <li>Coffee & Walnut - Coffee sponge, coffee buttercream & walnuts</li>
          <li>Chocolate - Chocolate sponge, chocolate butter cream</li>
          <li>Double Chocolate - Chocolate sponge with white chocolate buttercream</li>
          <li>Hummingbird - Spiced banana & pineapple sponge, cream cheese & lime frosting</li>
          <li>Carrot - Spiced carrot sponge, vanilla cream cheese frosting</li>
          <li>Coconut, white chocolate & raspberry - Coconut sponge, white chocolate buttercream, raspberry jam</li>
          <li>Malteser - Malted sponge, chocolate buttercream, maltesers</li>
          <li>Victoria Sponge - vanilla sponge, passionfruit curd, fresh cream (gf)</li>
          <li>Mocha - Coffee sponge, coffee buttercream, chocolate buttercream (gf)</li>
          <li>Cappucino- Coffee sponge, vanilla buttercream (gf)</li>
          <li>Lime & Coconut - Coconut & lime sponge, passionfruit curd, lime icing (gf)</li>
          <li>Orange & Pistachio - Orange & pistachio sponge, orange cream cheese frosting (gf)</li>
          <li>Salted caramel marble - Vanilla & chocolate marbled sponge, vanilla buttercream, salted caramel ganache, salted caramel (gf)</li>
          <li>Lemon Meringue - Lemon sponge, lemon curd, fresh cream (gf)</li>
          <li>Chocolate Orange - Orange sponge, orange curd, chocolate buttercream (gf)</li>
          <li>Double Chocolate - Chocolate sponge, white chocolate buttercream, toasted marshmallow (gf)
          </li>
        </ul>

        <br/>
        <p>(gf) - gluten free

        </p>
        <div className="pricing">
          <span>£3.95 per person</span>
        </div>

      </div>
    </>
  );
};

export default DesertsMenu;