import React from "react";

const CanapesMenu = () => {
  return (
    <>
      <div className="menu_item">
        <h4>Canapes</h4>
        <p>Chose any 5</p>
        <ul className="no-bullet">
          <li>Pea & Prawn Crostini</li>
          <li>Beetroot with garlic mushrooms crostini (v)</li>
          <li>Beetroot & goats cheese crostini (v)</li>
          <li>Hot & spicy chicken skewers</li>
          <li>Watermelon, cured ham & Feta</li>
          <li>Mozzarella, sundried tomato & basil (v)</li>
          <li>Devils of horse back</li>
          <li>Roquefort & peach bruschetta (v)</li>
          <li>Scallops & black pudding (£2 per person supplement)</li>
          <li>Pate & mushroom marmalade crostini</li>
          <li>Chicken Caesar boat</li>
          <li>Smoked salmon, cream cheese & dill crostini</li>
        </ul>
        <br/>
        <div className="pricing">
          <span>£9.50 per person</span>
        </div>

      </div>
    </>
  );
};

export default CanapesMenu;