import React from "react";

const SaladMenu = () => {
  return (
    <>
      <div className="menu_item">
        <h4>Salads</h4>

        <ul className="no-bullet">
          <li>Garlic &amp; potato salad Waxy potatoes, Dijon mustard, lemon juice finished with chopped chives</li>
          <li>Tanzanian vegetable rice salad</li>
          <li>Lemon &amp; pomegranate couscous - topped with fresh mint &amp; coriander</li>
          <li>Garden pea, cucumber &amp; mint salad</li>
          <li>Cannellini bean, feta &amp; mint salad</li>
          <li>Classic English garden salad</li>
          <li>Classic Caesar salad with homemade dressing</li>
          <li>Tim’s home-made sweet coleslaw</li>
          <li>Summer Macaroni salad - with crispy pancetta, &amp; petit peas in a creamy lemon-thyme dressing</li>
        </ul>
        <br/>
        <div className="pricing">
          <span>£4.50 per person</span>
        </div>

      </div>
    </>
  );
};

export default SaladMenu;