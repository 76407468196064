import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Row, Col, Container, Tab, Nav } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HogRoastMenu from "../components/menus/hogRoast"
import BbqMenu from "../components/menus/bbq"
import NextBbqMenu from "../components/menus/nextBbq"
import SaladMenu from "../components/menus/salad"
import CanapesMenu from "../components/menus/canapes"
import DesertsMenu from "../components/menus/deserts"

const HeaderSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "home-feature-chicken.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className="page-header"
          fluid={imageData}
          backgroundColor="#f2f3f3"
        >
          <Row>
            <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }} className="feature-bg">
              <h2>
                Menus
              </h2>
            </Col>
          </Row>
        </BackgroundImage>
      )
    }}
  />
)

const MenusPage = () => (
  <Layout pageInfo={{ pageName: "menus" }}>

    <SEO title="Hog Roast & BBQ Menus" keywords={[
      `flame and hog`,
      `hog roast`,
      `lamb roast`,
      `bbq catering`
    ]} />

    <div className="grey-bg">

      <HeaderSection/>

      <Container className="global-padding">

        <Tab.Container defaultActiveKey="hog">

              <Nav fill className="justify-content-center menu-tabs ">
                <Nav.Item>
                  <Nav.Link eventKey="hog">Hog Menu</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="bbq">BBQ Menu</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="next">Next Level Up BBQ Menu</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="sides">Sides &amp; Salads</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="canapes">Canapes</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="deserts">Desserts</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content className="menu-bg">
                <Tab.Pane eventKey="hog">
                  <HogRoastMenu/>
                </Tab.Pane>
                <Tab.Pane eventKey="bbq">
                  <BbqMenu/>
                </Tab.Pane>
                <Tab.Pane eventKey="next">
                  <NextBbqMenu/>
                </Tab.Pane>
                <Tab.Pane eventKey="sides">
                  <SaladMenu/>
                </Tab.Pane>
                <Tab.Pane eventKey="canapes">
                  <CanapesMenu />
                </Tab.Pane>
                <Tab.Pane eventKey="deserts">
                  <DesertsMenu/>
                </Tab.Pane>
              </Tab.Content>

        </Tab.Container>
      </Container>
    </div>

  </Layout>
)

export default MenusPage
